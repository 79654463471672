<!--
  - MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Website: https://www.4myth.com
  -->

<template>
  <v-container class="mb-16">
    <v-card
      elevation="2"
    >
      <v-card-title>
        {{ $t('custom.newSurvey') }}
      </v-card-title>
      <v-divider />
      <v-card-text>
        <slide-fade-transition>
          <v-container
            v-if="!message"
            fluid
          >
            <v-row>
              <v-col
                class="text-center"
                cols="12"
              >
                <v-img
                  class="mx-auto"
                  src="/pet2023.png"
                  width="300px"
                />
              </v-col>
              <v-col cols="12">
                <h3 class="font-weight-bold text-h5">
                  {{ $t('custom.newSurvey_subtitle') }}:
                </h3>
              </v-col>
            </v-row>
            <v-row>
              <app-text-input
                v-model="form.company_name"
                cols="12"
                name="company_name"
                required
                sm="6"
              />
            </v-row>
            <v-row v-show="form.company_name">
              <template
                v-for="(inx) in 6"
              >
                <v-col
                  v-show="form[`survey${inx-1}`] || inx === 1"
                  :key="`ff${inx}`"
                  cols="12"
                  md="6"
                >
                  <div>{{ parseAttribute(`survey${inx}`) }}*</div>
                  <v-rating
                    v-model="form[`survey${inx}`]"
                    background-color="primary"
                    color="primary"
                    hover
                    length="5"
                    size="40"
                  />
                </v-col>
              </template>
              <v-col
                v-show="form.survey6"
                class="py-2"
                cols="12"
              >
                <div class="text-h5">
                  {{ parseAttribute('survey7') }}
                </div>
                <v-radio-group
                  v-model="form.survey7"
                  row
                >
                  <v-radio
                    :label="parseAttribute('yes')"
                    :value="!0"
                  />
                  <v-radio
                    :label="parseAttribute('no')"
                    :value="!1"
                  />
                </v-radio-group>
              </v-col>
              <v-col
                v-show="form.survey7 !== null"
                class="py-2"
                cols="12"
              >
                <div class="text-h5">
                  {{ parseAttribute('survey8') }}
                </div>
                <v-radio-group
                  v-model="form.survey8"
                  row
                >
                  <v-radio
                    :label="parseAttribute('yes')"
                    :value="!0"
                  />
                  <v-radio
                    :label="parseAttribute('no')"
                    :value="!1"
                  />
                </v-radio-group>
              </v-col>
              <app-textarea-input
                v-show="form.survey8 !== null"
                v-model="form.suggestion"
                clearable
                cols="12"
                name="suggestion"
              />
            </v-row>
          </v-container>
        </slide-fade-transition>
        <slide-fade-transition>
          <v-container
            v-if="message"
            fluid
          >
            <v-row>
              <v-col
                class="text-center"
                cols="12"
              >
                <v-img
                  class="mx-auto"
                  src="/pet2023.png"
                  width="300px"
                />
              </v-col>
              <v-col
                class="text-center"
                cols="12"
              >
                <div class="text-h5">
                  {{ message }}
                </div>
                <app-btn
                  class="mt-5"
                  color="primary"
                  @click="startAgain()"
                >
                  <div class="text-h6">
                    {{ $t('custom.survey_start_again') }}
                  </div>
                </app-btn>
              </v-col>
            </v-row>
          </v-container>
        </slide-fade-transition>
      </v-card-text>
      <v-divider />
      <v-card-actions id="actions">
        <app-btn
          v-show="(form.survey8 !== null && !message)"
          :disabled="disabled"
          :loading="loading"
          @click="submit"
        >
          <v-icon left>
            send
          </v-icon>
          {{ $t('send') }}
        </app-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>

import { MetaInfoMixin } from '@mixins'
import { isDevelopment } from '@helpers/Tools'
import SlideFadeTransition from '@components/SlideFadeTransition.vue'

export default {
  name: 'NewSurvey',
  components: { SlideFadeTransition },
  mixins: [MetaInfoMixin],
  data () {
    return {
      loading: !1,
      message: null,
      form: {
        company_name: null,
        survey1: null,
        survey2: null,
        survey3: null,
        survey4: null,
        survey5: null,
        survey6: null,
        survey7: null,
        survey8: null,
        suggestion: null
      }
    }
  },
  computed: {
    disabled () {
      return !1
    }
  },
  watch: {
    form: {
      handler (v) {
        this.gotoActions()
      },
      deep: true
    }
  },
  mounted () {

  },
  methods: {
    isDevelopment () {
      return isDevelopment
    },
    gotoActions () {
      this.$vuetify.goTo(document.getElementById('actions'), {
        duration: 1000
      })
    },
    startAgain () {
      this.message = null
      this.form = {
        company_name: null,
        survey1: null,
        survey2: null,
        survey3: null,
        survey4: null,
        survey5: null,
        survey6: null,
        survey7: null,
        survey8: null,
        suggestion: null
      }
    },
    async submit () {
      if (this.loading) return
      this.loading = !0
      this.message = null
      // setTimeout(() => {
      //   this.message = 'Thank you'
      //   this.loading = !1
      // }, 1500)
      try {
        const { _data, _message } = await this.apiService.survey.inquiry(this.form)
        this.message = _message || null
      } catch (e) {
        const { _message } = e || {}
        _message && this.alertError(_message)
      } finally {
        this.loading = !1
      }
    }
  }
}
</script>
